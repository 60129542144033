/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/store'; 
import { trackEvent } from '@/services/amplitudeService';
import i18n from '../main'; // Import i18n from main.js




const HomeView = () => import('@/views/HomeView.vue');
const NotFound404 = () => import('@/views/NotFount404.vue');
const ProductsPage = () => import('@/views/ProductsPage.vue');
const GenerateDesign = () => import('@/views/GenerateDesign.vue');
const GenerateDesignV1 = () => import('@/views/GenerateDesigns-v1.vue');
const ViewDesigns = () => import('@/views/ViewDesigns.vue');
const EditDesign = () => import('@/views/EditDesign.vue');
const SingleProduct = () => import('@/views/SingleProduct.vue');
const LoginPage = () => import('@/views/LoginPage.vue');
const CreateOrder = () => import('@/views/CreateOrder.vue');
const ThankYou = () => import('@/views/ThankYou.vue');
const ImprintView = () => import('@/views/ImprintView.vue');
const AllProducts = () => import('@/views/AllProducts.vue');
const PrivacyPolicy = () => import('@/views/PrivacyPolicyView.vue');
const TermsAndConditions = () => import('@/views/termsAndConditions.vue');
const CookiePolicyView = () => import('@/views/cookiePolicyView.vue');
const ReturnPolicyView = () => import('@/views/returnPolicyView.vue');
const AboutUs = () => import('@/views/aboutUs.vue');
const testMe = () => import('@/views/testMe.vue');

const routes = [
  { path: `/`, name: 'home', component: HomeView },
  { path: '/login', name: 'loginPage', component: LoginPage },
  { path: '/products/:ProductTag', name: 'ProductsPage', component: ProductsPage },
  { path: '/products', name: 'AllProducts', component: AllProducts },
  { path: '/EditDesign/:UserProductID', name: 'EditDesignPage', component: EditDesign, meta: { requiresAuth: true }},
  { path: '/product/:productName', name: 'GenerateDesign', component: GenerateDesign },
  { path: '/productV1/:productName', name: 'GenerateDesignV1', component: GenerateDesignV1 },

  { path: '/ViewDesigns/:productID/:promptID', name: 'ViewDesigns', component: ViewDesigns, meta: { requiresAuth: true }},
  { path: '/SingleProduct/:userProductID', name: 'singleProduct', component: SingleProduct, meta: { requiresAuth: true }},
  { path: '/CheckOut/:userProductID/:selectedVariant', name: 'checkOut', component: CreateOrder, meta: { requiresAuth: true }},
  { path: '/success/:orderID/:checkOutSessionID', name: 'thankYou', component: ThankYou, meta: { requiresAuth: true }},
  { path: '/imprint', name: 'imprint', component: ImprintView },
  { path: '/privacypolicy', name: 'PrivacyPolicy', component: PrivacyPolicy },
  { path: '/termsandconditions', name: 'termsandconditions', component: TermsAndConditions },
  { path: '/cookiepolicy', name: 'cookiePolicyView', component: CookiePolicyView },
  { path: '/returnpolicy', name: 'returnPolicyView', component: ReturnPolicyView },
  { path: '/aboutus', name: 'aboutus', component: AboutUs },
  { path: '/testme', name: 'aboutus', component: testMe },
  { path: '/404', name: '404BadPage', component: NotFound404 },
  { path: '/:catchAll(.*)', name: '404Page', component: NotFound404 }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
});

// Authentication Guard
router.beforeEach((to, from, next) => {
  let token = store.getters.getToken;
  let tokenExpiry = store.getters.getExp;
  const tokenFromUrl = to.query.token;
  const expFromUrl = to.query.exp;

  if (tokenFromUrl && expFromUrl) {
    store.commit('setToken', { token: tokenFromUrl, exp: expFromUrl });
    localStorage.setItem('token', tokenFromUrl);
    localStorage.setItem('exp', expFromUrl);
    token = tokenFromUrl;
    tokenExpiry = expFromUrl;
    return next({
      path: to.path,
      query: Object.fromEntries(Object.entries(to.query).filter(([key]) => key !== "token" && key !== "exp")),
      replace: true
    });
  }

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && (!token || ((Date.now() / 1000) > tokenExpiry))) {
    return next({ name: 'loginPage', query: { redirect: to.fullPath } });
  }

 // let savedLang = localStorage.getItem('locale') || 'en'; 
 const savedLang = to.query.lang ? to.query.lang : (localStorage.getItem('locale') || 'en');
 localStorage.setItem('locale', savedLang);
  if (!savedLang) {
    
  }
  next();
});

// Page Tracking
router.afterEach((to, from) => {
  const toParams =
    to.params && Object.keys(to.params).length > 0
      ? Object.keys(to.params).reduce((acc, key) => {
          acc[`${key}`] = to.params[key];
          return acc;
        }, {})
      : { noParams: true };

  trackEvent('Page View', {
    pageName: to.name || 'Unknown Page',
    path: to.path,
    fromPage: from.name || 'Direct Access',
    fromPath: from.path || null,
    ...toParams
  });
});

export default router;
