import Vuex from 'vuex';
import axios from 'axios';

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    exp: localStorage.getItem('exp') || null,  // Store exp in state
    currency: 'EUR', // Default currency
  },
  mutations: {
    setToken(state, { token, exp }) {
      state.token = token;
      state.exp = exp;
      console.log("sotre got token",exp);
      // Persist in localStorage
      localStorage.setItem('token', token);
      localStorage.setItem('exp', exp);
    },
    clearToken(state) {
      state.token = null;
      state.exp = null;

      // Remove from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('exp');
    },
    setCurrency(state, newCurrency) {
      state.currency = newCurrency;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('/apis/login', credentials);

        const token = response.data.token;
        const exp = response.data.exp;

        // Commit token to Vuex store and persist in localStorage
        commit('setToken', { token, exp });

        return true; // Login successful
      } catch (error) {
        console.error('Login failed:', error);
        return false; // Login failed
      }
    },
    async tempRegister({ commit }, credentials) {
      try {
        const response = await axios.post('/apis/tempRegister', credentials);
        const token = response.data.token;
        const exp = response.data.exp;

        // Commit token to Vuex store and persist in localStorage
        commit('setToken', { token, exp });

        return true;
      } catch (error) {
        console.error('Temporary registration failed:', error);
        return false;
      }
    },
    updateTokenFromUrl({ commit }, { token, exp }) {
      // When receiving token from URL, update Vuex store and localStorage
      commit('setToken', { token, exp });
    },
    logout({ commit }) {
      commit('clearToken');
      console.log('Logged out');
    },
    updateCurrency({ commit }, newCurrency) {
      commit('setCurrency', newCurrency);
    },
  },
  getters: {
    getToken: state => state.token, // Get the token from the state
    getExp: state => state.exp, 
    isLoggedIn: state => !!state.token,
    currentCurrency: state => state.currency,
  },
});
